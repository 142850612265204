import { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { CreateNewService, uploadFile, EditService, getServiceDetailsById, getServicePricesById } from "../../../api/adminApi";
import { Image } from "react-bootstrap";
import { START_LOADING, STOP_LOADING } from "../../../redux/Slice/loaderSlice";
import { notifySuccess, notifyError, notifyInfo, notifyWarning } from '../../../api/toasterService';
import FormLabelStar from "../../core/FormLabelStar";
import { validationLength } from "../../../metaData/validationLength"
import { Stack } from "@mui/material";
import { AntSwitch } from "../../core/ReusableTable";

const InitialForm = {
  serviceCategoryId: "",
  name: "",
  imageUrl: "",
  scopeOfWork: [],
  description: "",
  recommendedTool: [],
  subService: [],
  safetyAndCaution: [],
  wowYourCustomer: [],
  inDemand: false,
  id: "",
  skillTrade: false,
  sequenceNumber: 0,
  isActive:true,
  active:true
};

const InitialPrice = {
  minimumPrice: 0,
  maximumPrice: 0,
  lowPriceLimit: 0,
  suggestedPriceLimit: 0,
  serviceId: 0
};

const addButtonFlags = {
  scopeOfWork: true,
  recommendedTool: true,
  subService: true,
  safetyAndCaution: true,
  wowYourCustomer: true,
}

const inDemandList = [
  {
    name: "True",
    id: true
  },
  {
    name: "False",
    id: false
  }
]

const isSkillTardeList = [
  {
    name: "True",
    id: true
  },
  {
    name: "False",
    id: false
  }
]

export default function AddNewService({ isAdded, service }) {
  const dispatch = useDispatch();
  // console.log("isAdded========>",isAdded)
  const serviceCategoryList = useSelector((state) => state.admin).categories;
  const [priceData, setPriceData] = useState({ ...InitialPrice, serviceId: service ? service?.id : 0 });
  const [formData, setFormData] = useState(InitialForm);
  const [file, setFile] = useState(null);
  const [validated, setValidated] = useState(false);
  const [openAddOption, setOpenAddOption] = useState(addButtonFlags);
  const [currentScopeOfWork, setcurrentScopeOfWork] = useState("");
  const [isScopeOfWorkValid, setIsScopeOfWorkValid] = useState(true);
  const [currentRecommendedTool, setcurrentRecommendedTool] = useState("");
  const [isRecommendedToolValid, setIsRecommendedToolValid] = useState(true);
  const [currentSubService, setcurrentSubService] = useState("");
  const [isSubServiceValid, setIsSubServiceValid] = useState(true);
  const [currentSafetyAndCaution, setcurrentSafetyAndCaution] = useState("");
  const [isSafetyAndCautionValid, setIsSafetyAndCautionValid] = useState(true);
  const [currentWowYourCustomer, setcurrentWowYourCustomer] = useState("");
  const [isWowYourCustomerValid, setIsWowYourCustomerValid] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDemand, setSelectedDemand] = useState("");
  const [selectedSkillTrade, setSelectedSkillTrade] = useState("");
  const [seqNo, setseqNo] = useState(0);

  const [isScopeOfWorkLenValid, setIsScopeOfWorkLenValid] = useState(true);
  const [isRecommendedToolLenValid, setIsRecommendedToolLenValid] = useState(true);
  const [isSubServiceLenValid, setIsSubServiceLenValid] = useState(true);
  const [isSafetyAndCautionLenValid, setIsSafetyAndCautionLenValid] = useState(true);
  const [isWowYourCustomerLenValid, setIsWowYourCustomerLenValid] = useState(true);
  const [lastPriceChangeName, setLastPriceChangeName] = useState('');
  const [validationError, setValidationError] = useState('');
  const [skillTradeValueFlag,setSkillTradeValueFlag] = useState(false)
  const [deactivateIsActive,setDeactivateIsActive] = useState(false)
  const [refreshPage,setRefreshPage] = useState(false)

  const categoryRef = useRef(null);
  const demandRef = useRef(null);
  const skillTradeRef = useRef(null);
  const fileInputRef = useRef(null);

  const isImageFile = (file) => {
    const allowedImageTypes = ["image/png", "image/jpeg", "image/jpg"];
    return allowedImageTypes.includes(file.type);
  };
  const handleFileChange = (e) => {
    console.log(e.target.files);
    const selectedFile = e.target.files[0];
    if (selectedFile && isImageFile(selectedFile)) {
      setFile(selectedFile);
    } else {
      fileInputRef.current.value = "";
      notifyWarning("Please select a valid image file.")
    }
  };


  useEffect(() => {
    if (service) {
      // console.log("service==========>",{ service });
      formData.isActive = service.isActive
      formData.active = service.isActive
      setSelectedCategory(service.serviceCategoryId)
      setSelectedDemand(service.isInDemand)
      setSelectedSkillTrade(service?.isSkillTrade || false)
      setseqNo(service.sequenceNumber || 0)
      // setFormData({
      //   ...service,
      //   inDemand: service.isInDemand,
      //   scopeOfWork: [],
      //   recommendedTool: [],
      //   subService: [],
      //   safetyAndCaution: [],
      //   wowYourCustomer: [],
      // })
      setOpenAddOption({
        scopeOfWork: false,
        recommendedTool: false,
        subService: false,
        safetyAndCaution: false,
        wowYourCustomer: false,
      })
      getAllServiceDetails(service)
      getServicePrice(service)
      // setDeactivateIsActive(service.isActive)
      
    }
  }, [service])

  const getAllServiceDetails = async (service) => {
    const allServiceDetail = await getServiceDetailsById(service.id);
    console.log({ allServiceDetail: allServiceDetail?.data });
    if (allServiceDetail?.data)
      setFormData({
        ...service,
        inDemand: service.isInDemand,
        scopeOfWork: allServiceDetail?.data.scopeOfWork,
        recommendedTool: allServiceDetail?.data.recommendedTool,
        subService: allServiceDetail?.data.subService,
        safetyAndCaution: allServiceDetail?.data.safetyAndCaution,
        wowYourCustomer: allServiceDetail?.data.wowYourCustomer,
      })
  };

  const getServicePrice = async (service) => {
    const servicePrice = await getServicePricesById(service.id);
    console.log({ servicePrice: servicePrice?.data });
    if (servicePrice?.data)
      setPriceData({
        ...priceData,
        minimumPrice: servicePrice?.data?.minimumPrice || 0,
        maximumPrice: servicePrice?.data?.maximumPrice || 0,
        lowPriceLimit: servicePrice?.data?.lowPriceLimit || 0,
        suggestedPriceLimit: servicePrice?.data?.suggestedPriceLimit || 0,
        serviceId: service.id
      })
  };

  const handleFileUpload = async () => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    const formData1 = new FormData();
    formData1.append("file", file);
    const fileResponse = await uploadFile(formData1);
    setFormData({
      ...formData,
      imageUrl: fileResponse.data,
    });
    return fileResponse.data;
  };
  // console.log(serviceCategoryList);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setSelectedCategory(Number(value))
    setFormData({
      ...formData,
      [name]: Number(value),
    });
    setSkillTradeValueFlag(!skillTradeValueFlag)
    // console.log(formData);
  };

  const handleChangeName = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeDesc = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeDemand = (event) => {
    const { name, value } = event.target;
    setSelectedDemand(value)
    setFormData({
      ...formData,
      [name]: value,
    });
    // console.log(formData);
  };

  const handleChangeSkillTrade = (event) => {
    console.log("event========>",event.target.name)
    console.log("event========>",event.target.value)
    const { name, value } = event.target;
    setSelectedSkillTrade(value)
    setFormData({
      ...formData,
      [name]: value,
    });

    setSkillTradeValueFlag(!skillTradeValueFlag)
  };

  const handleSubmit = async (event) => {
    // console.log({ openAddOption });
    try {


      event.preventDefault();
      // Validate the form
      const form = event.currentTarget;
      let isValidated = true;
      if (priceData.minimumPrice >= priceData.maximumPrice) {
        setLastPriceChangeName("maximumPrice")
        setValidationError('Maximum price is less than or equal to minimum price');
        isValidated = false;
      } else if (priceData.lowPriceLimit <= priceData.minimumPrice || priceData.lowPriceLimit >= priceData.maximumPrice || priceData.lowPriceLimit >= priceData.suggestedPriceLimit) {
        setLastPriceChangeName("lowPriceLimit")
        setValidationError('Low price limit should be between minimum & maximum price and less than Suggested price limit');
        isValidated = false;
      }
      else if (priceData.suggestedPriceLimit <= priceData.minimumPrice || priceData.suggestedPriceLimit >= priceData.maximumPrice) {
        setLastPriceChangeName("suggestedPriceLimit")
        setValidationError('Suggested price limit should be between minimum and maximum price');
        isValidated = false;
      }

      if (formData.scopeOfWork.length === 0 || openAddOption.scopeOfWork) {
        // setIsScopeOfWorkValid(false)
        setIsScopeOfWorkLenValid(false)
        isValidated = false;
      } else {
        setIsScopeOfWorkLenValid(true)
      }
      if (formData.recommendedTool.length === 0 || openAddOption.recommendedTool) {
        // setIsRecommendedToolValid(false)
        setIsRecommendedToolLenValid(false)
        isValidated = false;
      } else {
        setIsRecommendedToolLenValid(true)
      }
      if (formData.subService.length === 0 || openAddOption.subService) {
        setIsSubServiceLenValid(false)
        isValidated = false;
      } else {
        setIsSubServiceLenValid(true)
      }
      if (formData.safetyAndCaution.length === 0 || openAddOption.safetyAndCaution) {
        setIsSafetyAndCautionLenValid(false)
        isValidated = false;
      } else {
        setIsSafetyAndCautionLenValid(true)
      }
      if (formData.wowYourCustomer.length === 0 || openAddOption.wowYourCustomer) {
        setIsWowYourCustomerLenValid(false)
        isValidated = false;
      } else {
        setIsWowYourCustomerLenValid(true)
      }

      if (!isValidated) {
        setValidated(true);
        return;
      }

      if (form.checkValidity() === false) {
        event.stopPropagation();
        setValidated(true);
        if (!isValidated)
          return;
        else {
          setIsScopeOfWorkLenValid(true)
          setIsRecommendedToolLenValid(true)
          setIsSubServiceLenValid(true)
          setIsSafetyAndCautionLenValid(true)
          setIsWowYourCustomerLenValid(true)
        }
        return;
      }
      console.log(formData);
      let url = ''
      dispatch(START_LOADING());
      if (file || !service) {
        url = await handleFileUpload();
      }

      const serviceDetail = { ...formData, imageUrl: url, servicePrice: { ...priceData }, sequenceNumber: seqNo };
      // formData.active = formData.isActive
      // console.log({ serviceDetail });
      // console.log({ openAddOption });
      let apiRes = ''
      if (service) {
        if (!url)
          serviceDetail.imageUrl = formData.imageUrl;
          console.log("formData====>",formData)
          // serviceDetail.skillTrade = formData.isSkillTrade
          // if(skillTradeValueFlag){
          //   serviceDetail.skillTrade = formData.isSkillTrade
          // }
          if(!formData?.skillTrade){
            serviceDetail.skillTrade = formData.isSkillTrade
          }

          


        console.log("serviceDetail====>",serviceDetail)
        // console.log("formData===>",formData)

        apiRes = await EditService(serviceDetail);
      } else {
        apiRes = await CreateNewService(serviceDetail);
      }
      if (apiRes)
        isAdded(true);
    } catch (error) {

    } finally {
      dispatch(STOP_LOADING());
    }
  };

  const scopeOfWorkHandleChange = (e) => {
    const scopeOfWork = e.target.value;
    setcurrentScopeOfWork(scopeOfWork)
    if (scopeOfWork.trim("") !== "")
      setIsScopeOfWorkValid(true)
  };

  const recommendedToolHandleChange = (e) => {
    const recommendedTool = e.target.value;
    setcurrentRecommendedTool(recommendedTool)
    if (recommendedTool.trim("") !== "")
      setIsRecommendedToolValid(true)
  };

  const subServiceHandleChange = (e) => {
    const subService = e.target.value;
    setcurrentSubService(subService)
    if (subService.trim("") !== "")
      setIsSubServiceValid(true)
  };

  const safetyAndCautionHandleChange = (e) => {
    const safetyAndCaution = e.target.value;
    setcurrentSafetyAndCaution(safetyAndCaution)
    if (safetyAndCaution.trim("") !== "")
      setIsSafetyAndCautionValid(true)
  };

  const wowYourCustomerHandleChange = (e) => {
    const wowYourCustomer = e.target.value;
    setcurrentWowYourCustomer(wowYourCustomer)
    if (wowYourCustomer.trim("") !== "")
      setIsWowYourCustomerValid(true)
  };

  const handleReset = (e) => {
    setFormData(InitialForm);
    setPriceData({ ...InitialPrice, serviceId: service ? service?.id : 0 })
    setOpenAddOption(addButtonFlags)
    setcurrentScopeOfWork("")
    setcurrentRecommendedTool("")
    setcurrentSubService("");
    setcurrentSafetyAndCaution("")
    setcurrentWowYourCustomer('')
    setFile(null);
    setSelectedCategory("")
    setseqNo(0)
    setTimeout(() => {
      categoryRef.current.selectedIndex = 0;
      demandRef.current.selectedIndex = 0;
      skillTradeRef.current.selectedIndex = 0
    }, 0)
    setValidated(false);
  };

  const saveScopeOfWork = () => {
    if (currentScopeOfWork.trim("") !== "") {
      setIsScopeOfWorkValid(true)
      setFormData({
        ...formData,
        scopeOfWork: [...formData.scopeOfWork, { name: currentScopeOfWork }],
      });
      setOpenAddOption({ ...openAddOption, scopeOfWork: false })
      setcurrentScopeOfWork("")
    }
    else
      setIsScopeOfWorkValid(false)
  };

  const saveRecommendedTool = () => {
    if (currentRecommendedTool.trim("") !== "") {
      setIsRecommendedToolValid(true)
      setFormData({
        ...formData,
        recommendedTool: [...formData.recommendedTool, { name: currentRecommendedTool }],
      });
      setOpenAddOption({ ...openAddOption, recommendedTool: false })
      setcurrentRecommendedTool("")
    }
    else
      setIsRecommendedToolValid(false)
  };

  const saveSubService = () => {
    if (currentSubService.trim("") !== "") {
      setIsSubServiceValid(true)
      setFormData({
        ...formData,
        subService: [...formData.subService, { name: currentSubService }],
      });
      setOpenAddOption({ ...openAddOption, subService: false })
      setcurrentSubService("")
    }
    else
      setIsSubServiceValid(false)
  };

  const saveSafetyAndCaution = () => {
    if (currentSafetyAndCaution.trim("") !== "") {
      setIsSafetyAndCautionValid(true)
      setFormData({
        ...formData,
        safetyAndCaution: [...formData.safetyAndCaution, { name: currentSafetyAndCaution }],
      });
      setOpenAddOption({ ...openAddOption, safetyAndCaution: false })
      setcurrentSafetyAndCaution("")
    }
    else
      setIsSafetyAndCautionValid(false)
  };

  const saveWowYourCustomer = () => {
    if (currentWowYourCustomer.trim("") !== "") {
      setIsWowYourCustomerValid(true)
      setFormData({
        ...formData,
        wowYourCustomer: [...formData.wowYourCustomer, { name: currentWowYourCustomer }],
      });
      setOpenAddOption({ ...openAddOption, wowYourCustomer: false })
      setcurrentWowYourCustomer("")
    }
    else
      setIsWowYourCustomerValid(false)
  };


  const handleRemove = (inputName, index) => {
    const updatedOptions = [...formData[inputName]];

    updatedOptions.splice(index, 1);

    setFormData({
      ...formData,
      [inputName]: updatedOptions,
    });
    if (updatedOptions.length === 0)
      setOpenAddOption({ ...openAddOption, [inputName]: true })
  };

  const handleAdd = (inputName) => {
    if (formData[inputName].length > 0)
      setOpenAddOption({ ...openAddOption, [inputName]: !openAddOption[inputName] })
  };

  const handleChangePrice = (event) => {
    let { name, value } = event.target;
    setLastPriceChangeName(name)
    if (value.startsWith('-')) {
      value = value.slice(1);
    }
    const parsedValue = parseInt(value, 10);

    if (parsedValue < 0 || parsedValue > 100000000) {
      setValidationError('Please enter a valid price between 0 and 100,000,000.');
    } else {
      setValidationError('');
      setPriceData({ ...priceData, [name]: parsedValue });
    }
  };

  const handleChangeSeqNo = (event) => {
    let { name, value } = event.target;
    setLastPriceChangeName(name)
    if (value.startsWith('-')) {
      value = value.slice(1);
    }
    const parsedValue = parseInt(value, 10);

    if (parsedValue < 0 || parsedValue > 100000000) {
      setValidationError('Please enter a valid sequence no. between 0 and 100,000,000.');
    } else {
      setValidationError('');
      setseqNo(value)
    }
  };

  const handleIsActive = (tempFormData,value)=>{
    // console.log("value=========>",value)
    formData.active = value
    formData.isActive = value
    setRefreshPage(!refreshPage)
  }
  // useEffect(()=>{
  //   console.log("effect")

  // },[refreshPage])

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit} id="yourForm">
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Category<FormLabelStar /></Form.Label>
          <Form.Select
            size="sm"
            aria-label=" Select Category"
            name="serviceCategoryId"
            onChange={handleChange}
            // onChange={filterServices}
            value={selectedCategory}
            required
            ref={categoryRef}
            disabled={service ? true : false}
          >
            <option value="" disabled>Select</option>
            {serviceCategoryList?.map((cat) => (
              <option value={cat.id}>{cat.name}</option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a category.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Name<FormLabelStar /></Form.Label>
          <Form.Control
            required
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChangeName}
            placeholder="service name"
            maxLength={validationLength.service.name.maxLength}
          />
          {formData.name.length > validationLength.service.name.maxLength - 1 && (
            <div className="province-name-validate">{validationLength.message(validationLength.service.name.maxLength)}</div>
          )}
          <Form.Control.Feedback type="invalid">
            Please enter a service name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Sequence Number<FormLabelStar /></Form.Label>
          <Form.Control
            required
            type="number"
            name="sequenceNumber"
            value={seqNo}
            onChange={handleChangeSeqNo}
            placeholder="Please enter sequence number"
          />
          {
            lastPriceChangeName === 'sequenceNumber' && validationError &&
            <Form.Text className="text-danger">{validationError}</Form.Text>
          }
          <Form.Control.Feedback type="invalid">
            Please enter sequence number.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>In Demand<FormLabelStar /></Form.Label>
          <Form.Select
            size="sm"
            aria-label=" Select demand"
            name="inDemand"
            onChange={handleChangeDemand}
            // onChange={filterServices}
            value={selectedDemand}
            required
            ref={demandRef}
          >
            <option value="" disabled>Select</option>
            {inDemandList?.map((cat) => (
              <option value={cat.id}>{cat.name}</option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select In demand.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Is Skill Trade<FormLabelStar /></Form.Label>
          <Form.Select
            size="sm"
            aria-label=" Select skill trade"
            name="skillTrade"
            onChange={handleChangeSkillTrade}
            // onChange={filterServices}
            value={selectedSkillTrade}
            required
            ref={skillTradeRef}
          >
            <option value="" disabled>Select</option>
            {isSkillTardeList?.map((cat) => (
              <option value={cat.id}>{cat.name}</option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select Is skill Trade.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Minimum Price<FormLabelStar /></Form.Label>
          <Form.Control
            required
            type="number"
            name="minimumPrice"
            value={priceData.minimumPrice}
            onChange={handleChangePrice}
            placeholder="Please enter minimum price"
          />
          {
            lastPriceChangeName === 'minimumPrice' && validationError &&
            <Form.Text className="text-danger">{validationError}</Form.Text>
          }
          <Form.Control.Feedback type="invalid">
            Please enter minimum price.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Maximum Price<FormLabelStar /></Form.Label>
          <Form.Control
            type="number"
            name="maximumPrice"
            value={priceData.maximumPrice}
            onChange={handleChangePrice}
            placeholder="Please enter maximum price"
            required
          />
          {
            lastPriceChangeName === 'maximumPrice' && validationError &&
            <Form.Text className="text-danger">{validationError}</Form.Text>
          }
          <Form.Control.Feedback type="invalid">
            Please enter maximum price.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Low Price Limit<FormLabelStar /></Form.Label>
          <Form.Control
            type="number"
            name="lowPriceLimit"
            value={priceData.lowPriceLimit}
            onChange={handleChangePrice}
            placeholder="Please enter low price limit"
            required
          />
          {
            lastPriceChangeName === 'lowPriceLimit' && validationError &&
            <Form.Text className="text-danger">{validationError}</Form.Text>
          }
          <Form.Control.Feedback type="invalid">
            Please enter low price limit
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Suggested Price Limit<FormLabelStar /></Form.Label>
          <Form.Control
            type="number"
            name="suggestedPriceLimit"
            value={priceData.suggestedPriceLimit}
            onChange={handleChangePrice}
            placeholder="Please enter suggested price limit"
            required
          />
          {
            lastPriceChangeName === 'suggestedPriceLimit' && validationError &&
            <Form.Text className="text-danger">{validationError}</Form.Text>
          }
          <Form.Control.Feedback type="invalid">
            Please enter suggested price limit.
          </Form.Control.Feedback>
        </Form.Group>
        {/* <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Recommended Tools</Form.Label>
          <Form.Control
            type="text"
            name="recommendedTool"
            value={formData.recommendedTool}
            onChange={handleChange}
            placeholder="Recommended Tools"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter recommended tools.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Scope Of Work</Form.Label>
          <Form.Control
            type="text"
            name="scopeOfWork"
            value={formData.scopeOfWork}
            onChange={handleChange}
            placeholder="Scope Of Work"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter scope of work.
          </Form.Control.Feedback>
        </Form.Group> */}
        <Form.Group className="mb-1" controlId="formBasicPassword"
        >
          <Form.Label >Scope Of Work<FormLabelStar /></Form.Label>{" "}
          <a
            className="link-btn"
            style={{ marginLeft: "20px", marginBottom: "15px" }}
            onClick={() => handleAdd('scopeOfWork')}
          >
            Add
          </a>
          {formData.scopeOfWork.map((o, index) => (
            <div className="d-flex justify-content-between mb-3" key={index}>
              <Form.Control
                size="sm"
                aria-label=" Select scopeOfWork"
                name="scopeOfWork"
                style={{ marginRight: "15px" }}
                value={o.name}
                disabled
              />
              <Button
                className="link-btn"
                onClick={() => handleRemove('scopeOfWork', index)}
                size="sm"
              >
                Remove
              </Button>
            </div>
          ))}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword"
        >
          {openAddOption['scopeOfWork'] && (
            <div className="save d-flex">
              <Form.Control
                size="sm"
                aria-label="add scopeOfWork"
                name="scopeOfWorkAdd"
                style={{ marginRight: "15px" }}
                onChange={scopeOfWorkHandleChange}
                value={currentScopeOfWork}
                required
                maxLength={validationLength.service.sentence.maxLength}
              />

              <Button onClick={saveScopeOfWork} size="sm" style={{ width: "73px", marginLeft: "15px" }}>{" "}Save  </Button>
            </div>
          )}
          {currentScopeOfWork?.length > validationLength.service.sentence.maxLength - 1 && (
            <div className="province-name-validate">{validationLength.message(validationLength.service.sentence.maxLength)}</div>
          )}
          {!isScopeOfWorkValid && <div className="province-name-validate">
            Please enter Scope Of Work.
          </div>}
          {!isScopeOfWorkLenValid && <div className="province-name-validate">
            Please save Scope Of Work.
          </div>}
        </Form.Group>

        <Form.Group className="mb-1" controlId="formBasicPassword"
        >
          <Form.Label >Recommended Tools<FormLabelStar /></Form.Label>{" "}
          <a
            className="link-btn"
            style={{ marginLeft: "20px", marginBottom: "15px" }}
            onClick={() => handleAdd('recommendedTool')}
          >
            Add
          </a>
          {formData.recommendedTool.map((o, index) => (
            <div className="d-flex justify-content-between mb-3" key={index}>
              <Form.Control
                size="sm"
                aria-label=" Select recommendedTool"
                name="recommendedTool"
                style={{ marginRight: "15px" }}
                value={o.name}
                disabled
              />
              <Button
                className="link-btn"
                onClick={() => handleRemove('recommendedTool', index)}
                size="sm"
              >
                Remove
              </Button>
            </div>
          ))}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword"
        >
          {openAddOption['recommendedTool'] && (
            <div className="save d-flex">
              <Form.Control
                size="sm"
                aria-label="add recommendedTool"
                name="recommendedToolAdd"
                style={{ marginRight: "15px" }}
                onChange={recommendedToolHandleChange}
                value={currentRecommendedTool}
                required
                maxLength={validationLength.service.sentence.maxLength}
              />

              <Button onClick={saveRecommendedTool} size="sm" style={{ width: "73px", marginLeft: "15px" }}>{" "}Save  </Button>
            </div>
          )}
          {currentRecommendedTool?.length > validationLength.service.sentence.maxLength - 1 && (
            <div className="province-name-validate">{validationLength.message(validationLength.service.sentence.maxLength)}</div>
          )}
          {!isRecommendedToolValid && <div className="province-name-validate">
            Please enter Recommended Tool.
          </div>}
          {!isRecommendedToolLenValid && <div className="province-name-validate">
            Please save Recommended Tool.
          </div>}
        </Form.Group>

        <Form.Group className="mb-1" controlId="formBasicPassword"
        >
          <Form.Label >Sub Service<FormLabelStar /></Form.Label>{" "}
          <a
            className="link-btn"
            style={{ marginLeft: "20px", marginBottom: "15px" }}
            onClick={() => handleAdd('subService')}
          >
            Add
          </a>
          {formData.subService.map((o, index) => (
            <div className="d-flex justify-content-between mb-3" key={index}>
              <Form.Control
                size="sm"
                aria-label=" Select subService"
                name="subService"
                style={{ marginRight: "15px" }}
                value={o.name}
                disabled
              />
              <Button
                className="link-btn"
                onClick={() => handleRemove('subService', index)}
                size="sm"
              >
                Remove
              </Button>
            </div>
          ))}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword"
        >
          {openAddOption['subService'] && (
            <div className="save d-flex">
              <Form.Control
                size="sm"
                aria-label="add subService"
                name="subServiceAdd"
                style={{ marginRight: "15px" }}
                onChange={subServiceHandleChange}
                value={currentSubService}
                required
                maxLength={validationLength.service.sentence.maxLength}
              />

              <Button onClick={saveSubService} size="sm" style={{ width: "73px", marginLeft: "15px" }}>{" "}Save  </Button>
            </div>
          )}
          {currentSubService?.length > validationLength.service.sentence.maxLength - 1 && (
            <div className="province-name-validate">{validationLength.message(validationLength.service.sentence.maxLength)}</div>
          )}
          {!isSubServiceValid && <div className="province-name-validate">
            Please enter Sub Service.
          </div>}
          {!isSubServiceLenValid && <div className="province-name-validate">
            Please save Sub Service.
          </div>}
        </Form.Group>

        <Form.Group className="mb-1" controlId="formBasicPassword"
        >
          <Form.Label >Safety And Caution<FormLabelStar /></Form.Label>{" "}
          <a
            className="link-btn"
            style={{ marginLeft: "20px", marginBottom: "15px" }}
            onClick={() => handleAdd('safetyAndCaution')}
          >
            Add
          </a>
          {formData.safetyAndCaution.map((o, index) => (
            <div className="d-flex justify-content-between mb-3" key={index}>
              <Form.Control
                size="sm"
                aria-label=" Select safetyAndCaution"
                name="safetyAndCaution"
                style={{ marginRight: "15px" }}
                value={o.name}
                disabled
              />
              <Button
                className="link-btn"
                onClick={() => handleRemove('safetyAndCaution', index)}
                size="sm"
              >
                Remove
              </Button>
            </div>
          ))}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword"
        >
          {openAddOption['safetyAndCaution'] && (
            <div className="save d-flex">
              <Form.Control
                size="sm"
                aria-label="add safetyAndCaution"
                name="safetyAndCautionAdd"
                style={{ marginRight: "15px" }}
                onChange={safetyAndCautionHandleChange}
                value={currentSafetyAndCaution}
                required
                maxLength={validationLength.service.sentence.maxLength}
              />

              <Button onClick={saveSafetyAndCaution} size="sm" style={{ width: "73px", marginLeft: "15px" }}>{" "}Save  </Button>
            </div>
          )}
          {currentSafetyAndCaution?.length > validationLength.service.sentence.maxLength - 1 && (
            <div className="province-name-validate">{validationLength.message(validationLength.service.sentence.maxLength)}</div>
          )}
          {!isSafetyAndCautionValid && <div className="province-name-validate">
            Please enter Safety And Caution.
          </div>}
          {!isSafetyAndCautionLenValid && <div className="province-name-validate">
            Please save Safety And Caution.
          </div>}
        </Form.Group>

        <Form.Group className="mb-1" controlId="formBasicPassword"
        >
          <Form.Label >Wow Your Customer<FormLabelStar /></Form.Label>{" "}
          <a
            className="link-btn"
            style={{ marginLeft: "20px", marginBottom: "15px" }}
            onClick={() => handleAdd('wowYourCustomer')}
          >
            Add
          </a>
          {formData.wowYourCustomer.map((o, index) => (
            <div className="d-flex justify-content-between mb-3" key={index}>
              <Form.Control
                size="sm"
                aria-label=" Select wowYourCustomer"
                name="wowYourCustomer"
                style={{ marginRight: "15px" }}
                value={o.name}
                disabled
              />
              <Button
                className="link-btn"
                onClick={() => handleRemove('wowYourCustomer', index)}
                size="sm"
              >
                Remove
              </Button>
            </div>
          ))}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword"
        >
          {openAddOption['wowYourCustomer'] && (
            <div className="save d-flex">
              <Form.Control
                size="sm"
                aria-label="add wowYourCustomer"
                name="wowYourCustomerAdd"
                style={{ marginRight: "15px" }}
                onChange={wowYourCustomerHandleChange}
                value={currentWowYourCustomer}
                required
                maxLength={validationLength.service.sentence.maxLength}
              />

              <Button onClick={saveWowYourCustomer} size="sm" style={{ width: "73px", marginLeft: "15px" }}>{" "}Save  </Button>
            </div>
          )}
          {currentWowYourCustomer?.length > validationLength.service.sentence.maxLength - 1 && (
            <div className="province-name-validate">{validationLength.message(validationLength.service.sentence.maxLength)}</div>
          )}
          {!isWowYourCustomerValid && <div className="province-name-validate">
            Please enter Wow Your Customer.
          </div>}
          {!isWowYourCustomerLenValid && <div className="province-name-validate">
            Please save Wow Your Customer.
          </div>}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Image<FormLabelStar /></Form.Label>
          <Form.Control
            ref={fileInputRef}
            accept=".png, .jpeg, .jpg"
            type="file"
            required={!formData.imageUrl}
            name="serviceImage"
            onChange={handleFileChange}
            placeholder="Select Service File"
          />
          {/* <div>
            {service && (service ? `Selected File: ${formData.imageUrl}` : 'No file selected')}
          </div> */}
          {service &&
            <Image className="modal-img" src={formData.imageUrl}
              style={{ maxWidth: '100%', height: 'auto', marginTop: '10px' }}
            ></Image>}
          {!service &&
            <Form.Control.Feedback type="invalid">
              Please select a image.
            </Form.Control.Feedback>
          }

        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Description<FormLabelStar /></Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="description"
            value={formData.description}
            onChange={handleChangeDesc}
            placeholder="Description"
            required
            maxLength={validationLength.service.description.maxLength}
          />
          {formData.description?.length > validationLength.service.description.maxLength - 1 && (
            <div className="province-name-validate">{validationLength.message(validationLength.service.description.maxLength)}</div>
          )}
          <Form.Control.Feedback type="invalid">
            Please enter description.
          </Form.Control.Feedback>
        </Form.Group>
        {
          <Form.Group className="mb-3" style={{display:"flex",alignItems:"center"}}>
          <Form.Label>Active<FormLabelStar /></Form.Label>
          <Stack>
            <AntSwitch onClick={()=>{handleIsActive(formData,!formData.isActive)}} checked={formData.isActive} inputProps={{ 'aria-label': 'ant design' }}  />
          </Stack>
        </Form.Group>

        }
        
        <div className="d-flex justify-content-between m-2">
          <Button variant="primary" type="submit" size="sm">
            Submit
          </Button>
          <Button variant="secondary" type="reset" size="sm" onClick={() => handleReset()}>
            Reset
          </Button>
        </div>
      </Form >
    </>
  );
}
