import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faSort,
  faSortAmountAsc,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import ShowModal from "./Modal";
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Box, Stack, Typography } from "@mui/material";
import { DatePicker, Select, Space } from 'antd';
import { formatDate, formatDateOnly, parseDate } from '../../utils/dateUtils';
import { formatPriceInUSD } from "../../utils/priceUtils";
const { RangePicker } = DatePicker;

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#008000', // Green
      },
    },
    '&.Mui-disabled': {
      '& + .MuiSwitch-track': {
        backgroundColor: '#FF0000', // Red
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));


const ReusableTable = ({ data, columns, actions, userStatusChange, handleColumnFilter, handleDateChange, handleSelectChange, actionWidth }) => {
 // console.log({ data, columns, actions});
  // console.log({ actionWidth });
  const [sortedData, setSortedData] = useState();
  const [sortKey, setSortKey] = useState(null);
  const [imageUrl, setImageUrl] = useState();
  const [showmodal, setShowmodal] = useState();
  // console.log(data, "reusable tabl");
  const [sortDirection, setSortDirection] = useState(1);
  useEffect(() => {
    setSortedData(data);
  }, [data]);
  const handleSort = (key, sortable) => {
    if (!sortable) {
      return;
    }
    if (key === sortKey) {
      setSortDirection(-sortDirection); // Toggle sorting direction on the same column
    } else {
      setSortKey(key);
      setSortDirection(1);
    }

    const sorted = [...sortedData].sort((a, b) => {
      if (a[key] < b[key]) return -1 * sortDirection;
      if (a[key] > b[key]) return 1 * sortDirection;
      return 0;
    });

    setSortedData(sorted);
  };
  const viewImage = (url) => {
    setImageUrl(url);
    setShowmodal(true);
  };

  // const handleFilter = (val, key) => {
  //   console.log(val, key);
  //   if (val.trim() == "") {
  //     let filtered = sortedData.filter((d) => {
  //       if (String(d[key]).includes(val)) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     });
  //     setSortedData(filtered);
  //   }
  //   let filtered = data.filter((d) => {
  //     if (String(d[key]).includes(val)) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  //   setSortedData(filtered);
  // };

  //function to get custom element link ,or button for cell

  const handleFilter = (val, key) => {
    console.log(val, key);

    const lowercaseVal = val.trim().toLowerCase(); // Convert search value to lowercase

    if (lowercaseVal === "") {
      let filtered = sortedData.filter((d) => {
        const lowercaseD = String(d[key]).toLowerCase(); // Convert data value to lowercase
        return lowercaseD.includes(lowercaseVal);
      });
      setSortedData(data);
    } else {
      let filtered = data.filter((d) => {
        const lowercaseD = String(d[key]).toLowerCase(); // Convert data value to lowercase
        return lowercaseD.includes(lowercaseVal);
      });
      setSortedData(filtered);
    }
  };

  function renderColumnContent(column, item, rowIndex, viewImage, userStatusChange, getCustomElement) {
    if (column.customFunction) {
      return column.customFunction(item)
    }
    switch (column.keyName) {
      case "imageUrl":
      case "image":
        return (
          <a
            style={{ color: "#2f80ed", cursor: "pointer" }}
            onClick={() => viewImage(item["imageUrl"])}
          >
            View
          </a>
        );

      case "isActive":
        return (
          <Stack direction="row" spacing={1} alignItems="center" style={{ justifyContent: 'center' }}>
            <AntSwitch disabled={item?.isDeleted} checked={item?.isDeleted ? false : item[column.keyName]} inputProps={{ 'aria-label': 'ant design' }} onChange={() => userStatusChange && userStatusChange(item)} />
          </Stack>
        );

      case "taskStatus":
        const status = item[column.keyName];
        return (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                status == 'CUSTOMER_CANCELLED' || status == 'HELPER_CANCELLED' ||
                  status == 'CANCELLED' || status == 'AUTO_CANCELLED'
                  ? '#d32f2f'
                  : status == 'TASK_STARTED' || status == 'HIRING_INITIATED' ||
                    status == 'HIRING_INITIATED'
                    ? '#f57c00'
                    : '#388e3c',
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {status}
          </Box>
        );

      case "SerialNo":
        return rowIndex + 1;

      case "price":
        return formatPriceInUSD(item[column.keyName])

      case "amount":
        return formatPriceInUSD(item[column.keyName])

      case "isDeleted":
        return item[column.keyName] ? 'Yes' : 'No'

      case "isVerified":
        return item[column.keyName] ? 'Yes' : 'No'

      case "isInDemand":
        return item[column.keyName] ? 'Yes' : 'No'

      case "isTwoHourRequired":
        return item[column.keyName] ? 'Yes' : 'No'

      case "scheduledTime":
        return formatDate(item[column.keyName])

      case "paymentInitiatedTime":
        return formatDate(item[column.keyName])

      case "createdAt":
        return formatDate(item[column.keyName])
      // case column.custom:
      //   return getCustomElement(item, column);

      default:
        return item[column.keyName]?.toString();
    }
  }

  const getCustomElement = (item, column) => {
    if (column.custom) {
      console.log(item);
      if (column.customContent.type == "link") {
        let path = column.customContent.path.replace(
          ":param",
          item[column.customContent.toReplaceWithKey]
        );
        return <Link to={path}>{column.customContent.label}</Link>;
      }
    }
  };

  const DATE_FORMAT = 'MMM DD, YYYY';
  return (
    <>
      <Table bordered hover={true} responsive className="reusable-table" >
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th
                key={col.keyName}
                onClick={() => handleSort(col.keyName, col.isSortable)}
                style={{ width: `${col.width || ""}` }}
                className={`${col.className}`}
              >
                {col.name}{" "}
                {col.isSortable && col.keyName === sortKey && (
                  <FontAwesomeIcon
                    icon={sortDirection === 1 ? faSortUp : faSortDown}
                  />
                )}
                {col.isSortable && col.keyName !== sortKey && (
                  <FontAwesomeIcon icon={faSort} />
                )}
              </th>
            ))}
            {actions && actions.length > 0 && <th className="action-btn">Actions</th>}
          </tr>
          <tr>
            {columns.map((col, index) => (
              <th key={index}>
                {

                }

                {(col.isFilterable && (col.keyName === 'scheduledTime' ||
                  col.keyName === 'paymentInitiatedTime' ||
                  col.keyName === 'payoutDateTime' || col.keyName === 'createdAt'
                )
                ) ?
                  (
                    <RangePicker
                      style={{ height: "25px" }}
                      onChange={handleDateChange}
                      format={DATE_FORMAT}
                    />
                  ) :
                  (col.isFilterable && (col.keyName === 'taskStatus' ||
                    col.keyName === 'paymentStatus' || col.keyName === 'paymentType' ||
                    col.keyName === 'payoutStatus' || col.keyName === 'skillTradeStatus'
                  )
                  ) ?
                    (<Select
                      defaultValue=""
                      style={{
                        height: "25px",
                        width: "95%"
                      }}
                      options={col.options}
                      onChange={(value, option) => handleSelectChange(value, option, col.keyName)}
                    />)
                    : (
                      col.isFilterable && <input
                        type="text"
                        onChange={(e) => handleColumnFilter ? handleColumnFilter(e.target.value, col.keyName) : handleFilter(e.target.value, col.keyName)}
                        className="filter-text"
                      ></input>
                    )
                }
              </th>
            ))}
            {actions && actions.length > 0 && <th style={{ width: (actions.length == 3) ? "200px" : "" }} className="action-btn"></th>}
          </tr>
        </thead>
        <tbody>
          {sortedData?.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, colIndex) => (
                <td
                  key={colIndex}
                  className={`${column.multiline ? "" : "wrap"} ${column.className}`}
                >
                  <div title={item[column.keyName]} className="truncate">
                    {/* {column.keyName == "imageUrl" || column.keyName == "image" ? (
                      <>
                        <a
                          style={{ color: "#2f80ed", cursor: "pointer" }}
                          onClick={() => viewImage(item["imageUrl"])}
                        >
                          View
                        </a>
                      </>
                    ) : column.keyName == "isActive" ? (
                      <Stack direction="row" spacing={1} alignItems="center" style={{ justifyContent: 'center' }}>
                        <AntSwitch checked={item[column.keyName]} inputProps={{ 'aria-label': 'ant design' }} onChange={() => userStatusChange && userStatusChange(item)} />
                      </Stack>
                    ) : column.keyName == "SerialNo" ? (
                      rowIndex + 1
                    ) : column.custom ? (
                      getCustomElement(item, column)
                    ) : (
                      item[column.keyName]?.toString()
                    )} */}
                    {
                      renderColumnContent(column, item, rowIndex, viewImage, userStatusChange, getCustomElement)
                    }
                  </div>
                </td>
              ))}
              {actions && actions.length > 0 && (
                <td style={{ width: actionWidth ? actionWidth : `${actions.length * 5}%`, textAlign: "center", }}
                  className="action-btn"
                >
                  {actions.map((action, actionIndex) => (
                    <Button
                      style={{ marginRight: "5px", "fontSize": "12px", "padding": "5px" }}
                      size="sm"
                      key={actionIndex}
                      variant={action.variant || "primary"}
                      onClick={() => action.clickAction(item)}
                    >
                      {action.label}
                    </Button>
                  ))}
                </td>
              )}
            </tr>
          ))}
          {sortedData?.length == 0 && (
            <tr>
              <td colSpan={actions && actions.length > 0 ? columns?.length + 1 : columns?.length} style={{ textAlign: "center" }}>
                No results found{" "}
              </td>
            </tr>
          )}
        </tbody>
      </Table >
      <ShowModal
        classname="modal-image"
        show={showmodal}
        body={<Image className="modal-img" src={imageUrl}></Image>}
        handleClose={() => setShowmodal(false)}
      ></ShowModal>
    </>
  );
};

export default ReusableTable;
